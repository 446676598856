import React from 'react';
import styles from './payment-failed.module.css';

export default function PaymentNotify() {
  return (<React.Fragment>
    <div className={`${styles.BookingDone}`}>
      <img src="/images/check.png" alt="" />
      <p className={`${styles.BookingSmTitle}`}>Payment Notify</p>
    </div>
  </React.Fragment>)
}